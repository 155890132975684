// eslint-disable-next-line import/no-extraneous-dependencies
import axios, { AxiosPromise } from 'axios'
import { TOKEN_KEY } from 'utils/constants'
import { deleteItem, getItem } from 'utils/persistenceUtils'

const api = axios.create({
  baseURL: process.env.REACT_APP_URL,
})

const defaultsHeadersAxios = (token: string) => {
  api.defaults.headers.common.Authorization = token && `Token ${token}`
}

api.interceptors.response.use(
  response => response,
  async error => {
    const userToken = getItem(TOKEN_KEY)
    if (error.response.status === 401 && !!userToken) {
      deleteItem(TOKEN_KEY)
      window.location.href = '/logout'
    }
    return Promise.reject(error)
  }
)

class Axios {
  // private authorizationToken = '';
  //
  // // Set authorization token
  // public setAuthorizationToken(token: string): void {
  //     this.authorizationToken = token;
  // }
  //
  // // Drop authorization token
  // public dropAuthorizationToken(): void {
  //     this.authorizationToken = '';
  // }

  // eslint-disable-next-line class-methods-use-this
  public get(endPoint: string, params = {}, config = {}): AxiosPromise {
    return api.get(endPoint, { ...config, params })
  }

  // eslint-disable-next-line class-methods-use-this
  public post(endPoint: string, params = {}): AxiosPromise {
    return api.post(endPoint, params)
  }

  // eslint-disable-next-line class-methods-use-this
  public put(endPoint: string, params = {}): AxiosPromise {
    return api.put(endPoint, params)
  }

  // eslint-disable-next-line class-methods-use-this
  public patch(endPoint: string, params = {}): AxiosPromise {
    return api.patch(endPoint, params)
  }

  // eslint-disable-next-line class-methods-use-this
  public delete(endPoint: string, params = {}): AxiosPromise {
    return api.delete(endPoint, { params })
  }
}

const EndPointService = new Axios()

export { Axios, EndPointService, defaultsHeadersAxios }

export default EndPointService
