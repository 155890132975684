import React, { createContext, useContext, useState } from 'react'

export type ModalContextTypes = {
  idCompanie: number | string
  setIdCompanie: React.Dispatch<React.SetStateAction<number>>
}

const ModalDataContext = createContext<ModalContextTypes>({ idCompanie: 1, setIdCompanie: () => null })

export type ModalProviderProps = {
  children: React.ReactNode
}

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [idCompanie, setIdCompanie] = useState(1)

  return <ModalDataContext.Provider value={{ idCompanie, setIdCompanie }}>{children}</ModalDataContext.Provider>
}

const useModal = () => useContext(ModalDataContext)

export { ModalProvider, useModal }
