import React, { createContext, useContext, useEffect, useState } from 'react'
import api from '../../services/api'

const AreasAllActivitContext = createContext(null) as any

export type ModalProviderProps = {
  children: React.ReactNode
}

const AreasAllActivitProvider = ({ children }: ModalProviderProps) => {
  const [areas, setAreas] = useState<any>([])

  const getAreasOfExpertise = async () => {
    try {
      const { data } = await api.get(`management/all/activities`)

      const datas = Object.keys(data).map(key => {
        const item = data[key]
        return {
          description: item.description,
          activities: item.activities,
          activitiesCount: item.activities.length,
          id: item.id,
        }
      })
      setAreas(datas)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAreasOfExpertise()
  }, [])
  return (
    <AreasAllActivitContext.Provider value={{ areas, getAreasOfExpertise }}>{children}</AreasAllActivitContext.Provider>
  )
}

const useAreasAllActivities = () => useContext(AreasAllActivitContext)

export { AreasAllActivitProvider, useAreasAllActivities }
