import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { TOKEN_KEY } from 'utils/constants'
import { getItem } from 'utils/persistenceUtils'

const PrivateRoute = () => {
  const auth = getItem(TOKEN_KEY)
  if (!auth) {
    return <Navigate to="/login" />
  }
  return <Outlet /> // Certifique-se de usar o Outlet aqui
}

export default PrivateRoute
