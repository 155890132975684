import { createTheme } from '@mui/material/styles'
import { green, purple } from '@mui/material/colors'
import { theme as themas } from 'wave-design-system-plus'

const theme = createTheme({
  palette: {
    primary: {
      main: themas.colors.blue40,
    },
    secondary: {
      main: themas.colors.blue50,
    },
  },
})

export default theme
