import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react'
import api from '../../services/api'

interface Portal {
  id: number
  name: string
  description: string
  is_monitored: boolean
  url: string
  pncp_name: string
  pncp_id: number
}

interface PortalContextData {
  portal: Portal[]
  getPortalById: (id: number) => Portal | undefined
}

interface PortalProviderProps {
  children: ReactNode
}

const PortalContext = createContext<PortalContextData | undefined>(undefined)

const PortalProvider = ({ children }: PortalProviderProps) => {
  const [portal, setPortal] = useState<Portal[]>([])

  const getPortal = async () => {
    try {
      const { data } = await api.get('monitoring/portals')
      const sortedData = data.sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name))
      setPortal(sortedData)
    } catch (error) {
      console.error('Failed to fetch portal data:', error)
    }
  }

  const getPortalById = (id: number) => {
    return portal.find(item => item.id === id)
  }

  useEffect(() => {
    getPortal()
  }, [])

  return <PortalContext.Provider value={{ portal, getPortalById }}>{children}</PortalContext.Provider>
}

const usePortal = (): PortalContextData => {
  const context = useContext(PortalContext)
  if (!context) {
    throw new Error('usePortal must be used within a PortalProvider')
  }
  return context
}

export { PortalProvider, usePortal }
