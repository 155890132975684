const TOKEN_KEY = 'token'

const setItem = (key: string, item: string) => {
  localStorage.setItem(key, item)
}

const getItem = (key: string) => localStorage.getItem(key)
const deleteItem = (key: string) => localStorage.removeItem(key)

export { setItem, getItem, deleteItem}
