import React, { createContext, useCallback, useContext, useState } from 'react'
import { BiChevronDownCircle, BiErrorAlt } from 'react-icons/bi'
import { FiAlertCircle } from 'react-icons/fi'
import { RiFileWord2Line, RiFireFill, RiPulseFill } from 'react-icons/ri'
import { TypeChildTypes } from 'types/typeChildren'

interface ToastContextData {
  addToast(message: Omit<ToastMessage, 'id'>): void

  toastError(title: string, description: string): void

  toastSuccess(title: string, description: string): void

  toastInfo(title: string, description: string): void

  toastMessageTypeCompany(company: string, description: string, portal: string): void

  toastMessageTypeKeyWords(company: string, description: string, portal: string): void

  toastMessageTypeChangeBidding(company: string, description: string, portal: string): void

  removeToast(id: string): void

  messages: ToastMessage[]
}

interface ToastMessage {
  id: string
  type: 'success' | 'error' | 'info' | 'warning'
  title?: string
  description?: string
  icon?: React.ReactNode
  company?: string
  portal?: string
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData)

const ToastProvider = ({ children }: TypeChildTypes) => {
  const [messages, setMessage] = useState<ToastMessage[]>([])

  const addToast = ({ type, title, description, icon, company, portal }: Omit<ToastMessage, 'id'>) => {
    const id = new Date()
    const toast = {
      id: id.toString(),
      type,
      title,
      description,
      icon,
      company,
      portal,
    }
    setMessage(oldMessage => [...oldMessage, toast])
  }

  const toastSuccess = (title: string, description: string) => {
    addToast({ type: 'success', title, description, icon: <BiChevronDownCircle /> })
  }

  const toastError = (title: string, description: string) => {
    addToast({ type: 'error', title, description, icon: <BiErrorAlt /> })
  }
  const toastInfo = (title: string, description: string) => {
    addToast({ type: 'info', title, description, icon: <FiAlertCircle /> })
  }

  const toastMessageTypeCompany = (company: string, description: string, portal: string) => {
    addToast({ type: 'warning', title: company, description, portal, icon: <RiFireFill /> })
  }
  const toastMessageTypeKeyWords = (company: string, description: string, portal: string) => {
    addToast({ type: 'info', title: company, description, portal, icon: <RiFileWord2Line /> })
  }
  const toastMessageTypeChangeBidding = (company: string, description: string, portal: string) => {
    addToast({ type: 'info', title: company, description, portal, icon: <RiPulseFill /> })
  }

  const removeToast = useCallback((id: string) => {
    setMessage(oldMessage => oldMessage.filter(messages => messages.id !== id))
  }, [])

  return (
    <ToastContext.Provider
      value={{
        toastError,
        addToast,
        toastSuccess,
        toastInfo,
        toastMessageTypeCompany,
        toastMessageTypeKeyWords,
        toastMessageTypeChangeBidding,
        removeToast,
        messages,
      }}
    >
      {children}
    </ToastContext.Provider>
  )
}

const useToast = () => {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('error useToast')
  }
  return context
}

export { ToastProvider, useToast }
