import React, { createContext, useContext, useState } from 'react'

export type ContentReloadTypes = {
  clinetListLifeReload: number
  setClientLifeReload: React.Dispatch<React.SetStateAction<number>>
  contractLifeReload: number
  setContractLifeReload: React.Dispatch<React.SetStateAction<number>>
}

const ContentReload = createContext<ContentReloadTypes>({
  clinetListLifeReload: 1,
  setClientLifeReload: () => null,
  contractLifeReload: 1,
  setContractLifeReload: () => null,
})

export type ContentReloadProviderTypes = {
  children: React.ReactNode
}

const ContentReloadProvider = ({ children }: ContentReloadProviderTypes) => {
  const [clinetListLifeReload, setClientLifeReload] = useState(1)
  const [contractLifeReload, setContractLifeReload] = useState(1)

  return (
    <ContentReload.Provider
      value={{ clinetListLifeReload, setClientLifeReload, contractLifeReload, setContractLifeReload }}
    >
      {children}
    </ContentReload.Provider>
  )
}

const useClientReload = () => useContext(ContentReload)

export { ContentReloadProvider, useClientReload }
