import React, { createContext, useContext, useState } from 'react'

export type SideBarContextTypes = {
  sidebarOpen: boolean
  setsidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SideBarContext = createContext<SideBarContextTypes>({ sidebarOpen: false, setsidebarOpen: () => null })

export type SideBarProviderProps = {
  children: React.ReactNode
}

const SideBarProvider = ({ children }: SideBarProviderProps) => {
  const [sidebarOpen, setsidebarOpen] = useState(false)

  return <SideBarContext.Provider value={{ sidebarOpen, setsidebarOpen }}>{children}</SideBarContext.Provider>
}

const useSideBar = () => useContext(SideBarContext)

export { SideBarProvider, useSideBar }
