import React from 'react'
import { SideBarProvider } from 'hook/useSidebar'
import { TypeChildTypes } from 'types/typeChildren'
import { ToastProvider } from 'hook/useToast'
import { ModalProvider } from 'hook/useModalData'
import { ContentReloadProvider } from 'hook/useContentReload'
import { AreasAllActivitProvider } from '../hook/useAreasAllActivities'
import { PortalProvider } from '../hook/usePortal'

const Providers = ({ children }: TypeChildTypes) => {
  return (
    <ToastProvider>
      <ContentReloadProvider>
        <SideBarProvider>
          <ModalProvider>
            <AreasAllActivitProvider>
              <PortalProvider>{children}</PortalProvider>
            </AreasAllActivitProvider>
          </ModalProvider>
        </SideBarProvider>
      </ContentReloadProvider>
    </ToastProvider>
  )
}

export default Providers
