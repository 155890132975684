import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import RoutesComponent from 'routes'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ThemeProvider } from '@mui/material'
import theme from 'styles/theme'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import Providers from 'context'
import { GlobalStyles } from 'styles/global'

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BrowserRouter>
        <Providers>
          <ThemeProvider theme={theme}>
            <StyledThemeProvider theme={theme}>
              <GlobalStyles />
              <RoutesComponent />
            </StyledThemeProvider>
          </ThemeProvider>
        </Providers>
      </BrowserRouter>
    </LocalizationProvider>
  )
}

export default App
